
import Vue from "vue";
import LayoutPlain from "@/layouts/LayoutPlain.vue";
import PasswordResetForm from "@/components/PasswordResetForm.vue";
const Component = Vue.extend({
  methods: {
    confirmPassword() {
      (this.$refs.confirm as any).resetPassword();
    }
  },
  created(): void {
    this.$emit("update:layout", LayoutPlain);
  },
  components: {
    "password-reset-form": PasswordResetForm
  },
  data() {
    return {
      dialog: false
    };
  }
});
export default Component;
