
import Vue from "vue";

export default Vue.extend({
  name: "login-form",
  props: {
    type: String
  },
  data() {
    return {
      email: "",
      password: "",
      passwordConfirm: ""
    };
  },
  created(): void {
    // reset login status
    this.$store.dispatch("authentication/logout");
  },
  computed: {
    comparePasswords(): true | string {
      return this.password === this.passwordConfirm
        ? true
        : "Passwörter stimmen nicht überein";
    },
    minlengthPassword(): true | string {
      return this.password.length >= 5 ? true : "Password zu kurz";
    }
  },
  methods: {
    async resetPassword() {
      const token = this.$route.query.token;
      const email = this.$route.query.email;
      if (
        !(this.$refs.form as any).validate() ||
        this.password !== this.passwordConfirm ||
        !token ||
        !email
      ) {
        return;
      }
      if (this.type === "reset" ) {
        this.$store.dispatch("authentication/resetPassword", {
          email: email,
          newPassword: this.password,
          confirmPassword: this.passwordConfirm,
          token: token
        });
      } else {
        this.$store.dispatch("authentication/confirmAccount", {
          email: email,
          newPassword: this.password,
          confirmPassword: this.passwordConfirm,
          token: token
        });
      }
     
    }
  },
  components: {}
});
